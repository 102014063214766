import React, { Component } from 'react';
import Web3 from 'web3'
import './App.css';
import Marketplace from '../abis/Marketplace.json'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import Fortmatic from 'fortmatic';
import Header from './Header'
import Footer from './Footer'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
// Pages
import Home from './pages/Home';
import Create from './pages/Create';
import AllNfts from './pages/marketplace/AllNfts';

import Arts from './pages/marketplace/Arts';
import Music from './pages/marketplace/Music';
import TradingCard from './pages/marketplace/TradingCard';
import Collectibles from './pages/marketplace/Collectibles';
import Sports from './pages/marketplace/Sports';
import Utility from './pages/marketplace/Utility';
import Support from './pages/resources/Support';
import Partners from './pages/resources/Partners';
import AccountSetting from './pages/settings/AccountSetting';
import Profile from './pages/settings/Profile';
import Collections from './pages/settings/Collections';
import Assets from './pages/Assets';
import VirtualWorlds from './pages/marketplace/VirtualWorlds';
import ConnectWallet from './pages/settings/ConnectWallet';
import Favorite from './pages/settings/Favorite';


class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      balance:'',
      productCount: 0,
      products: [],
      productAcs: [],
      product: [],
      productOne: [],
      productHome:[],
      productMusic:[],
      productArt:[],
      productSports:[],
      productUtility:[],
      productColl:[],
      productTrading:[],
      disconnect:[],
      marketplace:'',
        imageHash: '',
        profile:[],
       buffer: null,
      loading: true,
      contract: null,
      AccountFM: '',
    }
  }
  async componentWillMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }
  // metamask connection
  async loadWeb3() {
       // Walletconnect
     const  provider = new WalletConnectProvider({
        rpc: {
        1: "https://mainnet.mycustomnode.com",
        3: "https://ropsten.mycustomnode.com",
        100: "https://dai.poa.network",
        // ...
        },
      });
console.log('provider')
console.log(provider)
      // Use Fortmatic provider
     const fm = new Fortmatic('pk_live_AF4A7E7982098866');
       // torus ethereum
      const torus = new Torus();
      const torusConn = await torus.init({
       buildEnv: "production", // default: production
        enableLogging: false, // default: false
           network: {
           host: "mainnet", // default: mainnet
           chainId: 1, // default: 1
           networkName: "Main Ethereum Network", // default: Main Ethereum Network
           },
        showTorusButton: false, // default: true
         });  
   console.log('torusConn')
   console.log(torusConn)
     
    if (window.ethereum) {
      // Use injected provider
       window.web3 = new Web3(window.web3.currentProvider);
    }
    
    else if ( provider.connected !== false ) {
          window.web3 = new Web3(provider);
          console.log('Walletconnect')
          console.log( window.web3)
     }
      // Use Fortmatic provider 
      else if ( !window.ethereum &&  provider.connected == false) { 
       fm.user.login().then(  async() => {
         const  isUserLoggedIn = await fm.user.isLoggedIn();
          if(isUserLoggedIn){
            window.web3 = new Web3(fm.getProvider());
            console.log('fortmatic')
            console.log(window.web3)
          }
        }); 
      }
     // torus ethereum
    else if (torusConn) {
      window.web3 = new Web3(torus.provider);
      console.log('torusConn')
      console.log(window.web3)
    }

  }


  async loadBlockchainData() {

    const web3 = window.web3
    // Load account
    var accounts =  await web3.eth.getAccounts()

    if(accounts.length !== 0){

      this.setState({ account: accounts[0] })
      await web3.eth.getBalance( accounts[0]).then(value => {
        this.setState (  { balance : web3.utils.fromWei(value, "ether")});
       });

      const networkId = await web3.eth.net.getId()
      const networkData = Marketplace.networks[networkId]
      if(networkData) {
           const marketplace = new web3.eth.Contract(Marketplace.abi, networkData.address)
           this.setState({ marketplace })
           const productCount = await marketplace.methods.productCount().call()
           this.setState({ productCount })

      // Load products
              for (let i = productCount; i > 0 ; i--) {
                 const product = await marketplace.methods.products(i).call()
                 this.setState({product : product})
                 this.setState({
                         products: [...this.state.products, product]
                       })
                      if(i === productCount){
                             this.setState({
                               productOne:  [...this.state.products]
                              })
                       }
                      if (i > productCount - 6) {
                        this.setState({
                          productHome:  [...this.state.products]
                        })
                      }
               }
               for (let i = 1; i <= productCount ; i++) {
                    const productAc = await marketplace.methods.products(i).call()
                    this.setState({
                            productAcs: [...this.state.productAcs, productAc]
                         })
                     if(productAc.category ==='arts'){
                          this.setState({
                            productArt:  [productAc]
                          })
                      }
                      if(productAc.category ==='music'){
                           this.setState({
                            productMusic: [productAc]
                          })
                      }
                      if(productAc.category ==='sports'){
                           this.setState({
                            productSports: [productAc]
                          })
                       }

                      if(productAc.category ==='collectibles'){
                            this.setState({
                            productColl:  [productAc ]
                          })
                      }
                      if(productAc.category ==='tradingCard'){
                           this.setState({
                           productTrading: [productAc ]
                          })
                      }
                      if(productAc.category ==='utility'){
                           this.setState({
                            productUtility: [productAc ]
                          })
                      }
                      if(productAc.category ==='virtualWorlds'){
                        this.setState({
                          productVirtual: [productAc ]
                        })
                    }
                  }

                this.setState({ loading: false})
              } else {
                window.alert('Marketplace contract not deployed to detected network.')
              }       
       }
  }

  purchaseProduct = (id, price) => {
    this.setState({ loading: true })
    this.state.marketplace.methods.purchaseProduct(id).send({ from: this.state.account, value: price })
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
    })
  }

  render() {
    return (
    
      <div >
         
          <Router>
              <div>
              <Header account={this.state.account}  balance={this.state.balance}  product={this.state.products} />
                <Switch>
                <Route exact path="/">
                         <Home  products={this.state.products}
                         productAcs={this.state.productAcs}
                         productOne={this.state.productOne}  
                         productHome = {this.state.productHome}  
                         productArt = {this.state.productArt}
                         productMusic = {this.state.productMusic} 
                         productSports = {this.state.productSports} 
                         productColl = {this.state.productColl}
                         productTrading = {this.state.productTrading} 
                         productUtility = {this.state.productUtility} />      
                 </Route>
                 {/* Marketplace */}
                  <Route path="/all-nfts">
                     <AllNfts   products={this.state.products}  />
                  </Route>
                  <Route path="/arts">
                     <Arts   products={this.state.products}  />
                  </Route>

                  <Route path="/music">
                     <Music   products={this.state.products}  />
                  </Route>
                  <Route path="/trading-card">
                     <TradingCard   products={this.state.products}  /> 
                  </Route>
                  <Route path="/virtual-worlds">
                     <VirtualWorlds   products={this.state.products}  /> 
                  </Route>
                  <Route path="/collectibles">
                     <Collectibles   products={this.state.products}  />
                  </Route>
                  <Route path="/sports">
                     <Sports   products={this.state.products}  />
                  </Route>
                  <Route path="/utility">
                     <Utility   products={this.state.products}  />
                  </Route>

                  <Route path="/support">
                     <Support/>
                  </Route>
                  <Route  path="/create">
                     <Create/>
                  </Route>
                  <Route  path="/partners">      
                     <Partners/>
                  </Route>
                  <Route  path="/account-settings"> 
                    <AccountSetting account={this.state.account} marketplace ={this.state.marketplace}  /> 
                  </Route>
                  <Route  path="/profile">      
                     <Profile account={this.state.account}/>
                  </Route>
                  <Route  path="/collections">      
                     <Collections account={this.state.account} products={this.state.products} />
                  </Route>
                  <Route  path="/assets/:id">      
                     <Assets products={this.state.products}
                           purchaseProduct={this.purchaseProduct}/>
                  </Route>
                  <Route  path="/wallet">      
                     <ConnectWallet />
                  </Route>
                  <Route  path="/favorites">      
                     <Favorite  account={this.state.account} products={this.state.products}/>
                  </Route>
                </Switch>
                <Footer/>
              </div>
            </Router> 
      </div>
    );
  }
}

export default App;
