import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import { Link } from "react-router-dom";

function Partners() {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      const data = {
        title: "FAQ (How it works)",
        rows: [
            {
                title: "What's a referral?,",
                content: `Our referral affiliate program allows users to earn between 40% and 100% of the fee that OpenSea receives
                 in successful sales for ETH on Ethereum mainnet.This guide explains how to refer buyers from your account page on OpenSea.
                 Your referral link will automatically be generated.
                 Any time a new user buys something on OpenSea, you’ll earn at least 1% of the sale! Referrals are processed in bulk once each month. Due to high gas prices, only referrals earning over .005 ETH will be processed.`,
            },
            {
                title: "Can Eternity verify me?",
                content:
                    `Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.Your referral 
                    link will automatically be generated. Any time a new user buys something on OpenSea, you’ll earn at least 1% of the sale! Referrals are processed in bulk once each month. Due to high gas prices, only referrals earning over .005 ETH will be processed.`,
            },
            {
                title: "What are Eternity's fees?",
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: "Can you help me with promotions?",
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: "What makes a great NFT offering?",
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: "How can I partner with Eternity?",
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
        ],
    };
    
    const styles = {
        bgColor: 'white',
        titleTextColor: "#212529",
        rowTitleColor: "#212529",
        rowContentColor: 'grey',
        arrowColor: "#212529",
    };
    const config = {
        animate: true,
        arrowIcon: "v",
        tabFocus: true
    };

    return (
        <div className = 'container'>
            <div className = 'partners pt-5 pb-5'>
                    <div className = 'partner-head'> 
                      <h2>Partners</h2>
                      <p>Learn how you can partner with us to showcase your NFT drops </p>
                    </div>
                    <div className = 'partner-body'>
                    <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
            </div>
        </div>
    )
}

export default Partners
