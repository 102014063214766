import React , {useEffect} from 'react'
import { Link } from "react-router-dom";

function Support() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []) 

    return (
    <div>
     <section>
    <div className="container mt-5 mb-5 ">
      <h1 className="my pb-3 ">Support Center</h1>
      <div className="content ">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="info">
                <h5 className="infos">Getting Started</h5>
                <p className="details">Learn how you can create an account, set up your wallet, and what you can do on Eternity.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="info">
                <h5 className="infos">Buying</h5>
                <p className="details">Learn how to purchase your first NFT and understand gas fees and what's gas free on Eternity.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="info">
                <h5 className="infos">Selling</h5>
                <p className="details">Learn how list your NFTs for sale and understand the different ways to list your NFTs.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="info">
                <h5 className="infos">Creating</h5>
                <p className="details">Learn how to create your very first NFT and how to create your NFT collections so you can begin selling and sharing.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="info">
                <h5 className="infos">FAQ</h5>
                <p className="details">Learn answers to frequently asked questions on Eternity.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="info">
                <h5 className="infos">User Safety</h5>
                <p className="details">Learn more about anti-fraud and user safety processes on Eternity.
                </p>
              </div>
            </div>
          </div> 
          <div className = 'partnrs'>
              
                 <div className="info2">
                   <Link to = '/partners' >
                       <h5 className="infos">Partners</h5>
                       <p className="details">Learn how you can partner with us to showcase your NFT drops. </p>
                  </Link>
                 </div>
              
              <div className="info2">
                     <h5 className="infos">Developers</h5>
                     <p className="details">Learn how you can develop with Eternity.
                </p>
              </div>
          </div>
          <h4 className="pro pt-5 pb-5">Promoted articles</h4>
          <div className="row">
            <div className="col-lg-4 col-md-12">
               <a href = 'https://support.opensea.io/hc/en-us/articles/360061676254-How-do-I-create-an-OpenSea-account-' target = '_blank' rel="noopener noreferrer"  ><p className="detailss">How do I create an Eternity account? </p></a>
            </div>
            <div className="col-lg-4 col-md-12">
              <a href = 'https://support.opensea.io/hc/en-us/articles/4404027708051-Which-blockchains-does-OpenSea-support-' target = '_blank'  rel="noopener noreferrer"  ><p className="detailss">Which blockchains does OpenSea support? </p></a>
            </div>
            <div className="col-lg-4 col-md-12">
                <a href   = 'https://support.opensea.io/hc/en-us/articles/1500009575482-How-do-royalties-work-on-OpenSea-' target = '_blank' rel="noopener noreferrer"   ><p className="detailss">How do royalties work on Eternity.Rest? </p>  </a>
            </div>
          </div>
          <div className="row">
             <div className="col-lg-4 col-md-12">
                   <a href = 'https://support.opensea.io/hc/en-us/articles/360063498333-How-do-I-list-an-NFT-to-sell-' target = '_blank' rel="noopener noreferrer"  > <p className="detailss">How do I list an NFT to sell?</p> </a>
             </div>
            <div className="col-lg-4 col-md-12">
                  <a href  = 'https://support.opensea.io/hc/en-us/articles/360063498313-How-do-I-create-an-NFT-' target = '_blank' rel="noopener noreferrer"  ><p className="detailss">How do I create an NFT?</p> </a>
            </div>
            <div className="col-lg-4 col-md-12">
                 <a href  = 'https://support.opensea.io/hc/en-us/articles/4403264773523-How-do-I-find-my-funds-on-Polygon-' target = '_blank' rel="noopener noreferrer" ><p className="detailss"></p>How do I find my funds on Polygon? </a>
            </div>
          </div>
          <div className="row">
             <div className="col-lg-4 col-md-12">
                   <a href = 'https://support.opensea.io/hc/en-us/articles/1500003230922-Where-can-I-find-NFT-sales-' target = '_blank' rel="noopener noreferrer"  > <p className="detailss">Where can I find NFT sales?</p> </a>
             </div>
            <div className="col-lg-4 col-md-12">
                  <a href  = 'https://support.opensea.io/hc/en-us/articles/4404423595667-How-can-I-report-fraudulent-content-on-OpenSea-' target = '_blank' rel="noopener noreferrer"  ><p className="detailss">How can I report fraudulent content on OpenSea?</p> </a>
            </div>
          </div>
      </div>
    </div>
  </section>
        </div>
    )
}

export default Support
