import React, { Component } from 'react';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import Fortmatic from 'fortmatic';
import Web3 from 'web3'

var backGroundImage = {
    width: "100%",
    height: "auto",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${process.env.PUBLIC_URL +  '../image/side1.png'})`
  };

class ConnectWallet extends Component {
    componentDidMount() {
       window.scrollTo(0, 0)
    }
// metamask connection
    async MetaMaskConnect() {
        if (window.ethereum) {
          window.web3 = new Web3(window.ethereum)
          const metaEnable = await window.ethereum.enable()
          if(metaEnable){
              window.location.href = '/'
          }
        }
      }
//  Create WalletConnect Provider
    async WalletConnect() {
        const provider = new WalletConnectProvider({
            rpc: {
            1: "https://mainnet.mycustomnode.com",
            3: "https://ropsten.mycustomnode.com",
            100: "https://dai.poa.network",
            // ...
            },
        });
        
        //  Enable session (triggers QR Code modal)
        await provider.enable();
    }
    // torus ethereum
    async TorusConnect() {
            const torus = new Torus();
            await torus.init();
            await torus.login(); // await torus.ethereum.enable()
    }
    // Fortmatic
    async  FortmaticConnect(){
        const fm = new Fortmatic('pk_test_8912F196963BF1F3');
        const web3 = new Web3(fm.getProvider());
        web3.currentProvider.enable();
    }


    trustConnect(){
        window.open('https://trustwallet.com/', '_blank');
    }
 
    render() {
        return (
            <div className = 'connect-to-wallet'>
                <div className = 'row'>
                    <div className = 'col-md-3' style={ backGroundImage }>
                             <img className = 'imgwallet' src= {`../image/logo.png` } alt = 'img' width="225px" height="auto"/> 
                     </div>
                     <div className = 'col-md-8 py-4 px-5'>
                         <div className = 'mt-4 mb-4 CreteClton'>
                               <h1>Connect your wallet</h1>
                               <p  className = 'pt-2 pb-3'>Connect with one of available wallet providers or create a new wallet. What is a wallet?</p>
                         </div>              
                         <div className = 'connect-wallet'>
                             <div className = 'metamask'>
                             <button className = 'walletbutton' onClick = {this.MetaMaskConnect}>
                                    
                                     <img src= {`../image/metamask.png` } alt = 'img'  width="225px" height="auto"/> 
                                     <p>One of the most secure wallets with great flexibility</p>
                                 </button>
                             </div>
                             <div className = 'metamask'>
                             <button className = 'walletbutton' onClick = {this.trustConnect}>
                                     <img src= {`../image/trast.png` } alt = 'img'  width="225px" height="auto"/> 
                                     <p>One of the most secure wallets with great flexibility</p>
                                </button>
                             </div>
                             <div className = 'metamask'>
                             <button className = 'walletbutton' onClick = {this.WalletConnect}>
                                     <img src= {`../image/WalletConnect.png` } alt = 'img'  width="225px" height="auto"/> 
                                     <p>One of the most secure wallets with great flexibility</p>
                                 </button>
                             </div>
                         </div>
                         <div className = 'connect-wallet-2'>
                             <div className = 'metamask'>
                             <button className = 'walletbutton'  onClick = {this.TorusConnect}>
                                     <img src= {`../image/torus_full_logo.png` } alt = 'img'  width="225px" height="auto"/> 
                                     <p>One of the most secure wallets with great flexibility</p>
                                 </button>
                             </div>
                             <div className = 'metamask'>
                             <button className = 'walletbutton' onClick = {this.FortmaticConnect}>
                                     <img src= {`../image/fortmatic.png` } alt = 'img'  width="225px" height="auto"/> 
                                     <p>One of the most secure wallets with great flexibility</p>
                                 </button>
                             </div>
                             <div className = 'metamask'>
                                 <button className = 'walletbutton'>
                                     <img src= {`../image/bitisrt.png` } alt = 'img'  width="225px" height="auto"/> 
                                     <p>One of the most secure wallets with great flexibility</p>
                                 </button> 
                             </div>
                         </div>
                     </div>
                </div>
            </div>
        );
    }
}

export default ConnectWallet;