// src/components/SearchList.js

import React from 'react';
import Card from './Card';


function SearchList( {filteredProduct} ) {
    console.log(filteredProduct)
    const filtered = filteredProduct.map(product => 
         <Card 
            key={product.id} 
            id = {product.id} 
            product={product.name} 
            image  = {product.imageHash}
            price = {product.price}
        />
        ); 
    
    return (
        <div>
        {filtered}
        </div>
    );
}

export default SearchList;