import React, { Component } from 'react'
import Sidebar from './Sidebar'
import Button from 'react-bootstrap/Button'
import { Nav , NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";


class Collections extends Component {
    constructor(props) {
        super(props)
        this.state = {
          loading: true,
        }
      }
    
        componentWillMount() {
          window.scrollTo(0, 0)
         this.ifLoading()
      }
      
       ifLoading = ()=>{    
        if(this.props.products){
          this.setState({ loading: false })
        }
      }

    render() {
        return (
            <div className = 'collections'>           
            <div className = 'row'>
                    <div className = 'col-md-3'>
                        <Sidebar/>
                    </div>
                    <div className = 'col-md-8 py-4'>
                    <div className = 'mt-4 mb-4 CreteClton'>  
                         <h1  >My Collections</h1> 
                        <p className = 'pt-2 pb-3'>Create, curate, and manage collections of unique NFTs to share and sell.</p>
                        <div className = 'CreteColl'>
                           <Link to="/create"><Button className = "createBtn">Create a Collection</Button></Link>
                           <NavDropdown 
                              className = "DropdownCollection"
                              title={
                                  <span className="nav-item-coll" ><i class="fas fa-ellipsis-v"></i></span>
                              }
                              >
                               <div className = 'nav-link'>  
                                   <a target="_blank"  rel="noopener noreferrer" href="https://rarible.com/create"  className="coll-item"><span style = {{marginRight:'10px'}}><img src= {`../image/raribale.jpg` } alt = 'img' width="35" height="35"/></span> Mint on Rarible </a> 
                                </div >  
                                <div className = 'nav-link'>  
                                   <a target="_blank" rel="noopener noreferrer" href="https://www.mintbase.io"  className="coll-item"><span style = {{marginRight:'10px'}}><img src= {`../image/mintbase.jpg` } alt = 'img' width="35" height="35"/></span> Mint on Mintbase </a> 
                                </div >
                                <div className = 'nav-link'>  
                                   <a target="_blank" rel="noopener noreferrer" href="https://app.cargo.build/marketplace?filter=%7B%7D" className="coll-item"><span style = {{marginRight:'10px'}}><img src= {`../image/cargo.png` } alt = 'img' width="35" height="35"/></span> Mint on Cargo </a> 
                                </div >
                                <div className = 'nav-link'>  
                                   <a target="_blank"  rel="noopener noreferrer" href="https://mintable.app" className="coll-item"><span style = {{marginRight:'10px'}}><img src= {`../image/mintable.jpg` } alt = 'img' width="35" height="35"/></span> Mint on Mintable </a> 
                                </div >
                                <div className = 'nav-link'>  
                                   <a target="_blank" rel="noopener noreferrer" href="https://zora.co" className="coll-item"><span style = {{marginRight:'10px'}}><img src= {`../image/zora.png` } alt = 'img' width="35" height="35"/></span> Mint on Zora </a> 
                                </div > 
                            </NavDropdown>
                            </div>
                         </div>
                       <hr></hr> 
                       {this.props.account ? 
                            <div className = 'collectionCard  mt-5'>
                        { this.state.loading 
                              ? <div id="loader" className="text-center"><p className="text-center">Loading...</p></div>
                              : <>
                            { this.props.products.map((product) => { 
                                return(
                                    <>
                                    { product.owner === this.props.account ? 
                                    <div className = 'nft-card-collection'>
                                    <Nav.Link >  
                                    <Link to="#">
                                    <div className = 'nft-home-card'>
                                             <div className = 'carsl-image'> <img  src={`https://ipfs.infura.io/ipfs/${product.imageHash}`} alt = "img" /></div> 
                                               
                                                  <div className = 'nft-cat-car'>
                                                   <div className = 'iamgeCircle'>  <img  src={`https://ipfs.infura.io/ipfs/${product.imageHash}`} alt = "img" /> </div>
                                                   <div className = 'cart-footer pt-4'>
                                                      <div className = 'cat-value'><span>{product.name}</span></div>
                                                      <div style = {{fontSize : '15px' , color:'#4a4a4a' }}><span>{product.description.slice(0,80).split(' ').slice(0, -1).join(' ')}</span></div>
                                                    </div>
                                                  </div> 
                                            </div>
                                    </Link> 
                                    </Nav.Link >    
                                    </div>
                                     : null
                                     }
                                    </>
                                )
                                })} 
                                </>
                               }
                            </div>

                         : 
                         <div className = 'no-items'>
                          <h1>No items to display</h1>
                         </div >
                       }
                      
                    </div>
            </div>


            
        </div>
        )
    }
}

export default Collections
